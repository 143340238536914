<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.subtitle
  font-weight: bold
  font-size: 1rem
  color: #777
.ghost
  opacity: 0.5
  // background: #c8ebfb
  // background-color: #fff !important
.dragging
  .flip-list-move
    transition: transform 0.5s
  .btn
    cursor: ns-resize
    background-color: #fff !important

.table-preview
  .sticky-header
    position: sticky
    top: -1px
    background-color: #f0f0f0
    z-index: 1000
    // outline: solid 1px #ccc
    // border-top: solid 1px #ccc
    box-shadow: inset 0px 0px 0px 1px rgba(0,0,0, 0.1), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.05)
    // border-bottom: 0
    color: #333 !important
  tbody
    tr
      td
        color: #555
        &.text-pre
          white-space: pre-line
.block-html
  // opacity: 0.3
  // transition: opacity 0.2s
  // &:hover
  //   opacity: 1
</style>
<template lang="pug">
div
  .d-flex.justify-content-center.align-items-top(style='min-height: 100vh')
    .col-10
      .mb-4
      h3.title.py-3 엑셀 가져오기
      div.async(:class='{done: done}')
        form.form(@submit.prevent='submit()')
          .pb-4

          .form-group
            label.d-block 내용 입력
            //- h3
              span.badge.badge-light.p-3 가져올 엑셀 내용을 복사, 붙여넣기 해주세요.
            textarea.form-control(rows=5 v-model='import_data' @blur='blur_import_data' autofocus placeholder='여기에 붙여넣기')
            span.text-muted 가져올 엑셀 내용을 복사, 붙여넣기 해주세요.

          .form-group.pt-4
            label.d-block 내용 확인
              button.btn.btn-light.text-primary.ml-2(type='button' @click='blur_import_data') 새로고침
            .bg-light.p-4.async(:class='{done: (step == 3)}')
              strong.text-dark {{prepare_text}}
              pre.pt-4.pb-0.mb-0(v-if='results.errors.length')
                ul
                  li(v-for='e in results.errors')
                    strong {{e.message}}
                    p {{e.code}}:{{e.type}}


          .pb-4
          .form-group.async(:class='{done: prepared}')
            .row
              .col-lg-6
                label.d-block 가져오기 진행률
                  span.ml-2.float-right ({{count_submit}}/{{results.data.length}})
                b-progress.shadow-sm(class="mt-2" :max="results.data.length" show-value)
                  b-progress-bar(:value="count_submit" variant="primary") 3
          .pb-4
          .form-group
            button.btn.btn-primary(type='submit' :disabled='saving || !prepared') {{saved_text}}
          .clearfix
          .pb-4

      .clearfix
      .mb-4

</template>

<script>

const Papa = require('papaparse')
const $ = require('jquery')

export default {
  name: 'index',
  props: ['property_id', 'document_id'],
  components: {

  },
  computed: {
    parsed_headers_mapping_by_excel() {
      const a = {}
      for (const k of Object.keys(this.parsed_headers_mapping)) {
        a[this.parsed_headers_mapping[k]] = k
      }
      return a
    },
    // session() {
    //   return this.$store.state.session
    // },
    // property() {
    //   return this.$store.state.property
    // },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    }
  },
  data() {
    return {
      done: false,
      saving: false,
      prepared: false,
      saved_text: '저장하기',

      prepare_text: '가져올 내용이 없습니다.',
      count_submit: 0,

      import_data: '',
      results: {
        data: [],
        errors: [],
        meta: {},
      },
      headers: [],

      current_page: 1,

      step: 1,

      document: {},
      error: '',

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },

      form: {
        customer_id: 'Y',
        customer_name: 'Y',
        customer_phone: 'Y',
        customer_email: 'Y',
      },

      last_col_id: 10,

      cols: [

      ],

      import_cols: {},

      should_save_cols: false,
      apply_header: 'N',
      parsed_headers: [],
      warning_header: [],
      parsed_headers_mapping: {
        '@고객이름': undefined,
        '@고객연락처': undefined,
        '@고객이메일': undefined,
        '@고객아이디': undefined,
      },
      apply_upsert: 'N',

      parse_type: 'rows',
      // parse_type: 'horizontal_header_rows',
      show_parse_option: false,
      parsed_h_headers: [],
      parsed_h_headers_hint: [],

      paste_message: '',

      preview_editing: false,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
    // this.load()
    // this.add_col()

    // this.fill_sample3()
    // this.apply_header = 'Y'
    // this.preview()

    // this.fill_sample4()
    // this.apply_header = 'Y'
    // this.preview()
    // this.parsed_headers_mapping['@고객이름'] = '고객명'
    // this.parsed_headers_mapping['@고객아이디'] = 'user_id'
    setTimeout(() => {
      // this.$modal.hide('preview')
//       this.import_data = `record_no	record_id	event_id	created_at	event	ip	email	response	useragent	url	url_offset_type	url_offset_index
// 66	tim	78	2021-04-19 17:17:13	processed		jinhyuk.lee@studiointo.asia
// 67	GVLKMU6QN9	79	2021-04-19 17:17:15	processed		hakbeom.kim@studiointo.asia
// 68	3DD9K1BWJY	80	2021-04-19 17:17:21	processed		jiho.kim@studiointo.asia
// 68	3DD9K1BWJY	81	2021-04-19 17:17:21	delivered	168.245.72.219	jiho.kim@studiointo.asia	250 2.0.0 OK  1618820233 f7si10725252ejw.608 - gsmtp
// 66	tim	82	2021-04-19 17:17:23	delivered	167.89.100.176	jinhyuk.lee@studiointo.asia	250 2.0.0 OK  1618820232 n3si16192983pgp.546 - gsmtp
// 67	GVLKMU6QN9	83	2021-04-19 17:17:25	delivered	167.89.100.176	hakbeom.kim@studiointo.asia	250 2.0.0 OK  1618820233 l3si801350plb.38 - gsmtp
// 68	3DD9K1BWJY	84	2021-04-19 17:17:28	open	74.125.216.67	jiho.kim@studiointo.asia		Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246 Mozilla/5.0
// 66	tim	85	2021-04-19 17:17:29	open	74.125.216.72	jinhyuk.lee@studiointo.asia		Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246 Mozilla/5.0
// 67	GVLKMU6QN9	86	2021-04-19 17:17:33	open	74.125.216.72	hakbeom.kim@studiointo.asia		Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246 Mozilla/5.0
// 67	GVLKMU6QN9	87	2021-04-19 17:17:53	open	66.249.84.156	hakbeom.kim@studiointo.asia		Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)
// 66	tim	88	2021-04-19 17:18:18	open	66.249.84.137	jinhyuk.lee@studiointo.asia		Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)
// 66	tim	89	2021-04-19 17:18:19	open	66.249.84.128	jinhyuk.lee@studiointo.asia		Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)`

//       this.import_data = `연락처	이름
// 01041029921	이진혁`
    }, 300);

    // this.fill_sample5()
    // this.preview()
    // this.fill_sample6()
  },
  methods: {
    blur_import_data() {
      if (this.import_data.length) {
        return this.preview()
      }
      this.step = 1
      this.prepared = false
      this.$modal.show('dialog', {
        title: '알림',
        text: '내용을 입력해주세요.',
      })
    },
    load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      if (!this.document.config) {
        this.document.config = {}
      }
      if (!this.document.config.cols) {
        this.document.config.cols = []
      }
      if (this.document.config.last_col_id) {
        this.last_col_id = this.document.config.last_col_id
      }
      const prev_cols = this.cols.slice()
      const prev_cols_by_key = {}
      for (const col of prev_cols) {
        prev_cols_by_key[col.key] = col
      }
      console.log('>>', prev_cols_by_key)
      this.cols = this.document.config.cols.slice()

      for (const col of this.cols) {
        const f = prev_cols_by_key[col.key]
        if (f) {
          col.value = f.value
        }
      }

      this.done = true
    },
    preview() {
      this.preview_editing = false

      if (this.parse_type == 'header_rows') {
        this.apply_header = 'Y'
      } else if (this.parse_type == 'horizontal_header_rows') {
        this.apply_header = 'Y'
      } else {
        this.apply_header = 'N'
      }

      const text = this.import_data.trim()
      if (!text || text.length === 0) {
        this.$modal.show('dialog', {
          title: '알림',
          text: ' 내용이 없습니다.',
        })
        this.step = 1
        return
      }

      let config = {}

      const r = Papa.parse(text, config)

      this.results = r
      if (this.results.errors.length) {
        this.prepare_text = `불러오기 실패`
        this.$modal.show('dialog', {
          title: '알림',
          text: this.prepare_text,
        })
        this.prepared = false
        this.step = 1
        return
      }
      if (this.results.data.length === 1) {
        this.prepare_text = `불러오기 실패`
        this.$modal.show('dialog', {
          title: '알림',
          text: '헤더와 내용, 최소 2줄 이상의 내용을 입력해주세요.',
        })
        this.prepared = false
        this.step = 1
        return
      }

      if (this.results && this.results.data) {
        this.parsed_headers = this.results.data[0]
        this.results.data = this.results.data.slice(1)
      }

      this.prepare_text = `${this.results.data.length}건의 데이터를 확인했습니다.`
      this.$modal.show('dialog', {
        title: '알림',
        text: this.prepare_text,
      })
      this.prepared = true


      this.step = 3
    },
    async submit() {
      try {
        this.saving = true

        this.saved_text = '저장 중'

        const new_cols = []

        for(const h of this.parsed_headers) {
          new_cols.push({
            key: 'd' + ++this.last_col_id,
            label: h,
            format: 'text',
            editable: true,
            position: 'secondary',
          })
        }

        const document = Object.assign({}, this.document, {
          config: Object.assign({}, this.document.config, {
            cols: new_cols,
            last_col_id: this.last_col_id,
            use_external_id: 'none',
          })
        })
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}`, document)
        if (r?.data?.message != 'ok') throw new Error('목록 항목 저장 실패 (고객데이터 추가전에 해당목록 항목을 먼저 저장이 필요합니다.)')

        if(!confirm(`${this.results.data.length}건을 추가할까요?`)) throw new Error('취소되었습니다.')

        for (const d of this.results.data) {
          const row = {}
          for (let i=0; i<d.length; i++) {
            // const label = this.parsed_headers[i]
            const col = new_cols[i]

            row[col.key] = String(d[i] || '')
          }
          const r = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {
            row,
          }, {
            params: {
              apply_upsert: this.apply_upsert,
            }
          })
          if (r?.data?.message != 'ok') throw new Error(`고객 저장 실패 (${this.count_submit+1}/${this.results.data.length}) ` + r?.data?.message)
          this.count_submit = this.count_submit+1
        }
        this.saved_text = '저장됨'


        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}/${this.$options.filters.encodeText(this.document.name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
        this.saved_text = '저장하기'
      }
      setTimeout(() => {
        this.saving = false
        this.$store.dispatch('documents', this.property_id)
      }, 300);
    },
  },
}
</script>
